
import { computed, defineComponent, onMounted, reactive, ref, toRaw } from "vue";
import { showModal, hideModal } from "@/core/helpers/dom";
import { translate, translateKey } from "@/hooks/TranslateService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { IUser } from "@/store/modules/valored/UserModule";
import { ErrorMessage, Field, Form as Form, useForm, useField } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import {
  ICompany,
  ICompanyYearlyParams,
  ICompanyPerYearParameters
} from "@/store/modules/valored/CompanyModule";
import * as Yup from "yup";
import { ICompanyYear } from "@/store/modules/valored/CompanyModule";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-company-setting-modal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: ["isCompanyCreate"],
  setup(props) {
    const { locale } = useI18n();
    const store = useStore();
    const route = useRoute();

    const addCompanySettingModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const submitButton = ref<HTMLElement | null>(null);

    const closeButton = ref<HTMLElement | null>(null);

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    onMounted(() => {
      store.dispatch(Actions.REQ_INITIAL_COMPARE_SELECT_OPTIONS);
      if (isAdmin.value) {
        // store.dispatch(Actions.REQ_COMPANY_PER_YEAR_PARAMETERS, route.params.id);
        // store.dispatch(Actions.REQ_COMPANY_LIST_YEAR, route.params.id);
      } else {
        store.dispatch(Actions.REQ_COMPANY_PER_YEAR_PARAMETERS);
        store.dispatch(Actions.REQ_COMPANY_LIST_YEAR);
      }
    });

    const selectLists = reactive(
      computed(() => store.getters.getSelectOptionLists)
    );

    const accountInfo = reactive({
      userProfile: computed(() => store.getters.currentUser as IUser),
      companyProfile: computed(
        () => store.getters.getCompanyProfile as ICompany
      ),
      companyYearlyParams: computed(
        () => store.getters.getCompanyYearlyParams as ICompanyYearlyParams
      ),
      companyPerYearParameters: computed(
        () => store.getters.getCompanyPerYearParamters as ICompanyPerYearParameters
      )
    });

    const optionYearlyPeriod = ref(
      computed(() => store.getters.getCompanyYearList as ICompanyYear)
    );

    const azienda_yearly_parameters = reactive(
      computed(() => {
        if (accountInfo.companyYearlyParams) {
          return accountInfo.companyYearlyParams.azienda_yearly_parameters;
        }
        return null;
      })
    );

    const company_per_year_parameters = reactive(
      computed(() => {
        if (accountInfo.companyPerYearParameters) {
          return accountInfo.companyPerYearParameters;
        }
        return null;
      })
    );

    const companyProfile = reactive(
      computed(() => {
        if (accountInfo) {
          return accountInfo.companyProfile;
        }
        return null;
      })
    );
    
    const isFormSubmitted = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      denom: Yup.string()
        .required(translate("COMPANY_NAME_REQUIRED"))
        .label("Company name "),
      settore: Yup.string()
        .required(translate("INDUSTRIAL_SECTOR_REQUIRED"))
        .label("Sector name"),
      fatturato: Yup.string()
        .required(translate("USERNAME_REQUIRED"))
        .label("Fatturato"),
      employees_range: Yup.string()
        .required(translate("EMPLOYEE_RANGE_REQUIRED"))
        .label("Employees range")
        .nullable(),
      is_associated: Yup.string()
        .required(translate("MEMBERSHIP_REQUIRED"))
        .label("Webside"),
      is_quoted: Yup.string()
        .required(translate("LISTING_REQUIRED"))
        .label("Country"),
      company_type: Yup.string()
        .required(translate("COMPANY_TYPE_REQUIRED"))
        .label("Company Type"),
    });

    const onShowModal = () => {  
      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD,
          isAdmin.value
            ? localStorage.getItem("to_year_admin")
            : localStorage.getItem("to_year")
        );
      }, 500);  
      showModal(addCompanySettingModalRef.value); 
    };

    const closeModal = (success: boolean) => {
      if (isAdmin.value) {
        localStorage.setItem(
          "to_year_admin",
          localStorage.getItem("from_year_modal") as string
        );
      } else {
        localStorage.setItem(
          "to_year", 
          localStorage.getItem("from_year_modal") as string
        );
      }
      setTimeout(() => {
        window.location.reload();
      }, 250);
    };

    const onSubmit = () => {
      isFormSubmitted.value = true;

      let values = getValues();
      profileDetailsValidator.validate(values).then((result) => {
        if (result) {
          isFormSubmitted.value = false;
          createCompanyProfile(values);
        }
      });
    };

    function getValues() {
      let values = {};
      (document.getElementById("kt_modal_add_customer") as HTMLElement).querySelectorAll("input, select").forEach((el) => {
        const key = el.getAttribute("name") as string;
        if (el.getAttribute("type") === "radio") {
          if (
            el.getAttribute("name") === "is_associated" ||
            el.getAttribute("name") === "is_quoted"
          ) {
            if ((el as HTMLInputElement).checked) {
              values[key] = (el as HTMLInputElement).value === "1" ? "1" : "0";
            }
          } else if (
            el.getAttribute("name") === "company_type"
          ) {
            if ((el as HTMLInputElement).checked) {
              values[el.getAttribute("name") as string] = 
                (el as HTMLInputElement).value === "D01" ? "D01" : (el as HTMLInputElement).value === "D02" ? "D02" : "D03";
            }
          }
        } else {
          values[key] = (el as HTMLInputElement | HTMLSelectElement).value;
        }
      });
      return values;
    }

    const createCompanyProfile = (values) => {  
      values["user_id"] = isAdmin.value ? JSON.parse(localStorage.getItem("simulate_user") as string)["id"] : "";

      hideModal(addCompanySettingModalRef.value);

      store.dispatch(Actions.CREATE_COMPANY_PROFILE, values).then(() => {
        if (isAdmin.value) {
          store.dispatch(Actions.REQ_COMPANY, route.params.id);
          store.dispatch(
            Actions.REQ_COMPANY_YEARLY_PARAMS, 
            JSON.parse(localStorage.getItem("simulate_user") as string).azienda_id
          );
          store.dispatch(Actions.REQ_COMPANY_PER_YEAR_PARAMETERS, route.params.id);
        } else {
          store.dispatch(Actions.REQ_COMPANY);
          store.dispatch(Actions.REQ_COMPANY_YEARLY_PARAMS);
          store.dispatch(Actions.REQ_COMPANY_PER_YEAR_PARAMETERS);
        }

        setTimeout(() => {
          window.location.reload();
        }, 250);
      });
    };

    return {
      loading,
      addCompanySettingModalRef,
      onShowModal,
      closeModal,
      onSubmit,
      translate,
      translateKey,
      selectLists,
      optionYearlyPeriod,
      azienda_yearly_parameters,
      company_per_year_parameters,
      profileDetailsValidator,
      createCompanyProfile,
      companyProfile,
      submitButton,
      closeButton,
      localStorage,
      isFormSubmitted,
      isAdmin,
      locale
    };
  },
});
